import { default as accordionInit } from "./accordion.js"

export default function() {

    var loginFormElement = $('.js-login-form');

    var onClose = function() {
        loginFormElement.hide()
        $('#popupFade').hide();
    }

    var showLogin = function() {
        $('.js-login-form .m-login--popup__reset p, .js-login-form .m-login--popup__reset form').fadeTo(200, 0, function() {
            $('.js-login-form .m-login--popup__reset').hide(400, function() {
                $('.js-login-form .m-login--popup__login').show('slow');
            });
        });
    }

    var showReset = function() {
        $('.js-login-form .m-login--popup__login')
            .hide(400, function() {
                $('.m-login--popup__reset-form').show();
                $('.m-login--popup__reset-complete').hide();
                $('.js-login-form .m-login--popup__reset').show('slow', function() {
                    $('.js-login-form .m-login--popup__reset p, .js-login-form .m-login--popup__reset form').fadeTo(200, 1);
                });
            });
    }

    var onReset = function(e) {
        var button = $(this);
        var form = button.closest('form');
        if (form.valid()) {
            $.ajax({
                url: '/booking/loginform/reset',
                data: form.serialize(),
                method: 'POST',
                success: function(response) {
                    if (response.message) {
                        form.find(".error-message .form-error").html(response.messge);
                    } else {
                        $('.m-login--popup__reset-form').hide();
                        $('.m-login--popup__reset-complete').show();
                        if (response.username) {
                            $('.m-login--popup__reset-complete span.username').html(response.username);
                        }
                    }
                },
                error: function(err) {
                    if (typeof console !== 'undefined') {
                        console.log(err);
                    }
                    form.find(".error-message .form-error").html('There has been a problem resetting your password. Please try again later');
                }
            });

            return false;
        }
    }

    var onLogin = function(e) {
        var button = $(this);
        var form = button.closest('form');
        if (form.valid()) {
            $.ajax({
                url: '/booking/loginform',
                data: form.serialize(),
                method: 'POST',
                success: function(response) {
                    if (response.message) {
                        form.find(".error-message .form-error").html(response.message);
                    } else if (response.url) {
                        window.location.href = response.url;
                    }
                },
                error: function(err) {
                    if (typeof console !== 'undefined') {
                        console.log(err);
                    }
                    form.find(".error-message .form-error").html('There has been a problem logging you in. Please try again later');
                }
            });

            return false;
        }
    }


    accordionInit();
    loginFormElement.off('click', '.m-login--popup__login .form-button').on('click', '.m-login--popup__login .form-button', onLogin);
    loginFormElement.off('click', '.m-login--popup__reset .form-button').on('click', '.m-login--popup__reset .form-button', onReset);
    loginFormElement.off('click', '.js-login-form-show-reset').on('click', '.js-login-form-show-reset', showReset);
    loginFormElement.off('click', '.js-login-form-show-login').on('click', '.js-login-form-show-login', showLogin);
    loginFormElement.off('click', '.js-login-form-close').on('click', '.js-login-form-close', onClose);
}
